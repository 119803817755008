import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/avadhani.jpg';

const AdvocateAvadhaniDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mr. V. S. R. Avadhani is a gold medal awardee in Law from Andhra
                University during 1977-80. As advocate he earned reputation as a
                civil lawyer. Joined judiciary in 1987 and became District Judge
                (Super time scale). He worked as Director of AP Judicial
                Academy, Registrar-Judicial in Supreme Court of India and was
                selected as Secretary General of the Apex Court in the cadre of
                Judge of High Court/Secretary to Government of India. In that
                capacity, he was instrumental in bringing radical changes in the
                administration of the Apex Court Registry, introduced e-cause
                list, and successfully completed scanning, digitization of
                records of the Supreme Court from 1947 up to 1991. As in-charge
                of Supreme Court Reports, the official law journal, he raised
                the subscription levels from 55 copies to 3000 copies. He has
                participated in several conferences as delegate from Indian
                Government and Judiciary, representing the Supreme Court. He was
                guest faculty to several State Judicial Academies, National
                Judicial Academy, and Police Academies. He is visiting faculty
                of Christ University Law School, Bengaluru, and teaching Indian
                Evidence Act. Joined as Judicial Member of National Company Law
                Tribunal, having resigned to the Judiciary, but also resigned to
                that post and incorporated Avadhani and Associates with a team
                of professionals. He is legal Advisor to several Corporate
                Bodies and Banks. He is acting as Arbitrator in several matters.
                He has several commentaries on various subjects of law which are
                well received by legal fraternity. His regular weekly column
                ‘Law Lochanam” in Andhra Jyothi, telugu daily is very popular.
                He is the Chief Editor of the Supreme Court Journal (SCJ).
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Mr. V.S.R. Avadhani. BSC.,BL.
                </h2>
                <p className='team-one__designation'>
                  Senior Partner, Senior Consultant and Advocate
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateAvadhaniDetails;
